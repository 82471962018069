import { useLocale } from 'next-intl'
import appConfig from '#/src/appConfig'
import { CustomLocale } from '#/src/i18n'

const useAppConfig = () => {
  const locale = useLocale()
  return appConfig(locale as CustomLocale['locale']).config
}

export default useAppConfig
